<template>
  <div class="authentication">
    <v-subtitle
      zh="权威认证"
      en="Authoritative attestation"
      type="mini"
    />
    <v-certificate />
    <v-subtitle
      zh="其他资质认证"
      zh-style="font-size: 16px; color:#333"
      type="mini"
    />
    <div class="banner100vw banner100vw-linear-gradient other-bg">
      <v-other />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vCertificate from './certificate.vue'
import vOther from './other.vue'
export default {
  components: {
    vSubtitle,
    vCertificate,
    vOther
  }
}
</script>

<style lang="less" scoped>
.authentication{
  .banner100vw();
  .other-bg{
    &::before{
      opacity: 0.6;
    }
  }
}
</style>
