<template>
  <div class="corporate-activities">
    <v-subtitle
      zh="企业活动"
      en="Corporate activities"
      type="mini"
    />
    <v-activitys />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vActivitys from './activitys.vue'
export default {
  components: {
    vSubtitle,
    vActivitys
  }
}
</script>

<style lang="less" scoped>
</style>
