<template>
  <div class="nav-bar">
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-2.png"
      :img-style="{transform: 'translate(-50%, -100%)'}"
    />
    <div class="nav-bar-list">
      <div
        class="nav-bar-list-item"
        v-for="(item, index) in navBarList"
        :key="index"
      >
        <span class="data">{{ item.data }}</span>
        <span class="name">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navBarList: [
        {
          data: '300+',
          name: '云产品服务'
        },
        {
          data: 'CMMI3',
          name: '国家级认证'
        },
        {
          data: 'CTBA',
          name: '会员单位'
        },
        {
          data: '3A',
          name: '信用单位'
        },
        {
          data: '48+',
          name: '软件著作专利'
        },
        {
          data: '500+',
          name: '单位合作对象'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.nav-bar {
  position: relative;
  padding: 27px 0;
  margin-bottom: 80px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: @mxnWith100vw;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
  }
  &-list {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .data{
        color: #014CD7;
        font-size: 32px;
        margin-bottom: 20px;
      }
      .name{
        font-size: 20px;
        color: #000;
      }
    }
  }
}
</style>
