<template>
  <div class="activitys">
    <div class="activitys-list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          :src="item.img"
          :alt="item.content"
        >
        <div class="item-content">
          <div class="theme">
            {{ item.theme }}
          </div>
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="activitys-more">
      <div
        class="btn"
        @click="loadingMore"
      >
        <template v-if="loading">
          <i class="el-icon-loading" />
        </template>
        <template v-else>
          点击加载更多
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      params: {
        page: 1,
        pageSize: 10
        // type: 1
      },
      // 加载状态
      loading: false,
      list: [
        {
          img: '/static/img/aboutUs/ac-1.png',
          theme: '2020年年会',
          content:
            '加深员工对企业的感情，增进员工之间的沟通和交流，加强员工之间的团队协作意识，创造企业内积极向上的工作氛围，贯彻“让交易变容易”的核心思想。'
        },
        {
          img: '/static/img/aboutUs/ac-2.png',
          theme: '欢乐泰国之旅',
          content:
            '为了鼓励优秀员工的付出，公司在2019年组织了优秀员工去海外体验异国风情，异国团建往往不只是日常工作中的熟悉，还会促进日常生活中的交流，更加增强员工之间的信任感和互相理解。'
        },
        {
          img: '/static/img/aboutUs/ac-3.png',
          theme: '1024程序员节',
          content:
            '在这追梦的时代，总有一些人低头创造未来，他们坚持对知识和技术的追求，用代码改变世界，用实际行动彰显创新，在10.24这个特殊的日子里，公司每年都会举办各种活动，员工可以获得键盘、鼠标等节日相关礼品。'
        },
        {
          img: '/static/img/aboutUs/ac-4.png',
          theme: '团建活动主题:让交易变容易',
          content:
            '公司一直以来以“让交易变容易”为核心目标，每年都会组织整体团建活动3次左右，通过团建来丰富员工的业余生活，放松工作压力，实现劳逸结合，增强员工之间的交流及团队凝聚力。'
        },
        {
          img: '/static/img/aboutUs/ac-5.png',
          theme: '同创造、齐分享、共飞扬:',
          content:
            '公司在2021年组织与兄弟企业团建活动，通过本次团建活动促进跨公司沟通，为未来努力实现同心同力的共同目标铺垫默契值。'
        },
        {
          img: '/static/img/aboutUs/ac-6.png',
          theme: '端午节',
          content:
            '为丰富公司员工生活，调动员工积极性，公司在2019举办端午团建活动，感受传统佳节文化底蕴的同时纪念爱国诗人屈原，传承中华民族传统文化。'
        }
      ]
    }
  },
  methods: {
    getActivityList (isMore) {
      console.log(this.params)
      const list = new Array(8).fill(0).map((i) => ({
        id: i,
        img: `/static/img/aboutUs/ac-${(i % 4) + 1}.png`,
        title: '欢乐普吉岛之行',
        content:
          '泰国由于本身没有台风与巨浪的威胁，普吉岛这个地方更是风和日丽，加上面积不是很大，外围更有大小岛屿十几个，每一个岛屿更是各有特色地美丽。'
      }))
      this.loading = true
      setTimeout(() => {
        this.list = isMore ? this.list.concat(list) : list
        this.loading = false
      }, 1000)
    },
    loadingMore () {
      this.loading = true
      this.params.page++
      this.getActivityList(true)
    }
  }
}
</script>

<style lang="less" scoped>
.activitys {
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
    grid-row-gap: 10px;
    place-items: center;
  }
  .item {
    height: 100%;
    width: 380px;
    background: #fff;
    box-shadow: @gBoxShadowNone;
    transition: @gTransition01s;
    border-radius: 4px;
    overflow: hidden;
    img {
      height: 278px;
      width: 100%;
      background: #eeeeee;
    }
    &-content {
      padding: 16px 18px;
      .theme {
        font-size: 16px;
        color: #000;
        margin-bottom: 10px;
        transition: @gTransition01s;
      }
      .content {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }
    }
    &:hover {
      box-shadow: @gBoxShadowHover;
      .theme{
        color: @mainColor;
      }
    }
  }
  // 加载更多
  &-more {
    text-align: center;
    margin-top: 40px;
    .btn {
      display: inline-block;
      width: 300px;
      height: 62px;
      line-height: 62px;
      text-align: center;
      background: #ffffff;
      font-size: 20px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 67px;
      cursor: pointer;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
      &:hover {
        background: #3a5fca;
        color: #fff;
      }
    }
  }
}
</style>
