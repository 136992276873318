<template>
  <div class="milepost">
    <v-subtitle
      zh="文锐里程碑"
      en="Milepost"
      type="mini"
    />
    <div class="milepost-map">
      <img src="/static/img/aboutUs/map.png">
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  }
}
</script>

<style lang="less" scoped>
.milepost{
  margin-bottom: 80px;
  &-map{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    .img{
      height: 1000px;
      width: 1000px;
      img{
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
