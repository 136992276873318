<template>
  <div class="other">
    <div class="other-list">
      <div
        v-for="(item, index) in otherList"
        :key="index"
        class="other-item"
      >
        <div class="img">
          <img :src="item.img">
        </div>
        <div
          class="text"
          v-html="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      otherList: [
        {
          img: '/static/img/aboutUs/other-1.png',
          text: 'ISO认证'
        },
        {
          img: '/static/img/aboutUs/other-2.png',
          text: 'EBC/CQC认证'
        },
        {
          img: '/static/img/aboutUs/other-3.png',
          text: '国家专利认证'
        },
        {
          img: '/static/img/aboutUs/other-4.png',
          text: '国家专栏认证'
        },
        {
          img: '/static/img/aboutUs/other-5.png',
          text: '企业荣誉认证'
        }

      ]
    }
  }
}
</script>

<style lang="less" scoped>
.other{
    margin-bottom: 50px;
    padding: 40px 0;
    position: relative;
    &-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        .img{
            width: 82px;
            height: 82px;
            overflow: hidden;
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 100%;
            }
        }
        .text{
            font-size: 20px;
            color: #000;
            text-align: center;
        }
    }
}
</style>
