<template>
  <div class="certificate">
    <div class="certificate-list">
      <div
        class="certificate-item"
        v-for="(item, index) in certificateList"
        :key="index"
      >
        <div class="img">
          <img :src="item.img">
        </div>
        <div class="text">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      certificateList: [
        {
          img: '/static/img/aboutUs/知识产权管理体系认证证书.png',
          title: '知识产权管理体系认证证书',
          content: '知识产权管理体系认证，是为了规范知识产权认证活动，提高其有效性，加强监督管理，根据《中华人民共和国专利法》《中华人民共和国商标法》《中华人民共和国著作权法》《中华人民共和国认证认可条例》《认证机构管理办法》等法律、行政法规以及部门规章的规定管理体系认证。'
        },
        {
          img: '/static/img/aboutUs/电子招标投标系统交易平台认证证书.png',
          title: '电子招标投标系统交易平台认证证书',
          content: '这套系统不但要解决招标方关于招标文件的电子发布、传送、招标公告发布、招标文件的下载等方面的问题，而且要解决投标方关于投标文件的投递安全性，投标时间的准确性与有效性，以及不同地域的评标专家能同时对电子标书的阅读、评审、相互之间交流等安全性、准确性等的问题'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.certificate {
  margin-bottom: 80px;
  &-list {
    display: flex;
    justify-content: space-between;
  }
  &-item {
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    width: 48%;
    background: #f5f7f9;
    box-shadow: 0px 0px 6px 1px rgba(73, 120, 250, 0.178);
    border: 3px solid #ffffff;
    .img{
        height: 196px;
        width: 140px;
        background: @imgBgColor;
        margin-right: 20px;
        img{
            height: 100%;
            width: 100%;
        }
    }
    .text{
        flex: 1;
        padding: 18px 0 20px;
        .title{
            font-size: 20px;
            color: #000;
            margin-bottom: 16px;
        }
        .content{
            font-size: 14px;
            color: #000;
            font-weight: 300;
            line-height: 1.4;
        }
    }
  }
}
</style>
