<template>
  <div class="introduction">
    <v-subtitle
      zh="企业介绍"
      en="Introduction"
      type="mini"
    />
    <div class="introduction-info">
      <div class="img">
        <img
          src="/static/img/aboutUs/introduction.png"
        >
      </div>
      <div class="content">
        <div class="text">
          &emsp;&emsp;甘肃文锐电子交易网络有限公司（简称“文锐公司”）成立于2007年，是一家专业研发和运营电子交易平台的高新技术企业，涉及公共资源交易及企业招采领域。<br>
          &emsp;&emsp;自成立以来，文锐公司时刻关注着行业需求，着注于各行业电子交易平台的研发，已经陆续开发出了面向公共资源交易领域的公共资源电子交易平台、面向企业招采领域的企业电子交易平台，涉及产品主要有电子服务系统、电子监管系统、建设工程电子招投标系统、政府采购电子交易系统、土地和矿业权交易系统、产权交易系统、药品采购系统、阳光招标采购系统、交易大数据平台、见证系统、专家库等软件系统等，支持招投标、挂牌竞价、议价、拍卖等多种交易模式。<br>
          &emsp;&emsp;公司定位为第三方交易平台运营商，以简化交易程序、降低交易成本、提高交易效率、实现阳光交易为公司的核心目标，并参与到交易完成后的履约与流通服务环节，为交易各方主体提供全面的信息技术服务。<br>
          &emsp;&emsp;系统严格按照国家电子招投标办法和相关技术规范研发运营，可以接入电子招投标公共服务平台和公共资源交易电子公共服务系统，为行业监管单位提供便捷的电子化监管服务。<br>
          &emsp;&emsp;公司拥有一支110多人的年轻的、有活力的技术团队，并在四川省成都市设立了科技研发中心，研发人员占公司总人数的70%以上。目前公司已经获得50多项软件著作权，是一家拥有企业认证管理体系和产品认证管理体系的企业。<br>
          &emsp;&emsp;“安全、高效、透明、便捷”是公司在交易系统研发和平台维护过程中追求的目标核心。注重市场主体在交易活动中的需求，为交易主体提供简单易用的软件和贴心精准的服务。采用先进的信息技术帮助交易平台优化服务流程，实现智能化监管，创建公开公平公正的市场环境。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  }
}
</script>

<style lang="less" scoped>
.introduction {
  padding-top: 50px;
  margin-bottom: 80px;
  &-info {
    background: #f6f8fd;
    box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    border: 2px solid #ffffff;
    padding: 40px 36px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .img{
      flex: 1;
      margin-right: 32px;
      background: @imgBgColor;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .content {
      height: 286px;
      width: 730px;
      font-size: 18px;
      color: #000;
      font-weight: 300;
      line-height: 30px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
        background: #eaeaea;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #4978fa;
        border-radius: 2px;
      }
      .text{
        padding:4px 10px 4px 0;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
}
</style>
