<template>
  <div class="ahout-us">
    <v-banner-box :data="swiperData">
      <template slot="button">
        <p />
      </template>
    </v-banner-box>
    <v-nav-bar />
    <v-intro />
    <v-milepost />
    <div class="banner100vw banner100vw-20p30t banner100vw-linear-gradient">
      <v-enterprise />
    </div>
    <v-authentication />
    <v-structure />
    <v-activity />
  </div>
</template>

<script>
// NOTE :  关于我们
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vIntro from './components/introduction/index.vue'
import vMilepost from './components/milepost/index.vue'
import vEnterprise from './components/enterprise/index.vue'
import vAuthentication from './components/authentication/index.vue'
import vStructure from './components/structure/index.vue'
import vActivity from './components/activity/index.vue'
export default {
  components: {
    vBannerBox,
    vNavBar,
    vIntro,
    vMilepost,
    vEnterprise,
    vAuthentication,
    vStructure,
    vActivity
  },
  watch: {},
  data () {
    return {
      swiperData: [],
      // 搜索框内容
      searchVal: ''
    }
  },
  created () {},
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['关于我们']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
  }
}
</script>

<style lang="less" scoped>
.ahout-us {
  .banner100vw();
}
</style>
