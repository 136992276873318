<template>
  <div class="structure">
    <v-subtitle
      zh="分支机构/参股公司"
      en="Branch/equity company"
      type="mini"
    />
    <div class="structure-map">
      <img src="/static/img/aboutUs/structure.png">
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  }
}
</script>

<style lang="less" scoped>
.structure{
  margin-bottom: 80px;
  &-map{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    img{
      height: 405px;
    }
  }
}
</style>
